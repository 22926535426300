import React from 'react';
import Layout from '../components/Layout/Layout';
import TestimonialPage from '../components/TestimonialPage/TestimonialPage';

const ServicesSite = () => {
  return(
    <Layout>
      <TestimonialPage />
    </Layout>
  )
}

export default ServicesSite;
