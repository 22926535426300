// extracted by mini-css-extract-plugin
export var choice = "TestimonialPage-module--choice--07X+y";
export var content = "TestimonialPage-module--content--qpSTN";
export var heading = "TestimonialPage-module--heading--7rhbm";
export var image = "TestimonialPage-module--image--FUYJj";
export var info = "TestimonialPage-module--info--h1NiB";
export var name = "TestimonialPage-module--name--uY-Lm";
export var player = "TestimonialPage-module--player--wwSKM";
export var playerContainer = "TestimonialPage-module--player-container--9nB10";
export var quote = "TestimonialPage-module--quote--saaid";
export var selected = "TestimonialPage-module--selected--L2xIO";
export var team = "TestimonialPage-module--team--bZGP+";
export var wrapper = "TestimonialPage-module--wrapper--iTyhU";