import React, { useState } from 'react';
import * as style from './TestimonialPage.module.scss'; 
import {Link, graphql, StaticQuery} from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const TestimonialPage = () => {
	const [selected, setSelected] = useState('elite')

	const renderPlayer = (player) => {
			return (
				<div className={style.player}>
					<GatsbyImage image={player.image.gatsbyImage} className={style.image} alt={player.name}/>
					<div className={style.info}>
						<h3 className={style.name}>{player.name}</h3>
						<div className={style.team}>{player.team}</div>
						<p className={style.quote}>{player.quote}</p>
					</div>
				</div>
			)
	}

	 return(
		 <div className={style.wrapper}>
			<div className={style.heading}>
				<h2>TRAIN LIKE THE BEST TO BECOME THE BEST</h2>
				<p>
					ESTFOOTBALL HAS HAD THE PLEASURE TO TRAIN A WHOLE BUNCH OF TALENTED PLAYERS. 
					HERE IS WHAT THEY HAD TO SAY ABOUT THE EXPERIENCE.
				</p>
			</div>
			<div className={style.choice}>
				<div className={selected === 'elite' && style.selected} onClick={() => setSelected('elite')}>Elite</div>
				<div className={selected === 'youth' && style.selected}onClick={() => setSelected('youth')}>Youth</div>
			</div>
			<StaticQuery query={graphql`
				{
					allWpPlayer {
					 edges {
						 node{
							 player{
								 name
								 position
								 team
								 quote
								 category
								 image{
									 gatsbyImage(width: 800, placeholder: BLURRED)
								 }
							 }
						 }
					 }
				 }
			 }
			`} render={props => (
				props.allWpPlayer.edges.map((player) => (
					<>	
						{
							player.node.player.category === selected &&
								renderPlayer(player.node.player)
						}
						
					</>
				))
			)} />
		 </div>
	 )
}
export default TestimonialPage;